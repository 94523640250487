var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.collectionCellComponent, {
    tag: "Component",
    attrs: {
      data: _vm.data,
      drafts: _vm.drafts,
      "object-fit": _vm.objectFit,
      multiselect: _vm.multiselect,
      locked: _vm.locked,
      selected: _vm.selectedRowState,
      "is-select-column": _vm.isSelectColumn
    },
    on: {
      select: _vm.onSelectRow,
      "row-action": _vm.onRowAction,
      "cell-value": _vm.onCellValueChange
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }