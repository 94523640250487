var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm._t("image", function() {
        return [
          _vm.data.image
            ? _c("LazyImage", {
                staticClass: "grid-collection-default-cell__image",
                attrs: {
                  href: "multimedia/" + _vm.data.image + "/download/default",
                  value: _vm.data.image,
                  "object-fit": _vm.objectFit
                }
              })
            : _c("DefaultImage", {
                staticClass: "grid-collection-default-cell__default-image"
              })
        ]
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "grid-collection-default-cell__title",
        attrs: { title: _vm.data.description },
        domProps: { textContent: _vm._s(_vm.data.description) }
      }),
      _vm._v(" "),
      _c("GridCollectionCellActionsPanel", {
        scopedSlots: _vm._u([
          {
            key: "prepend",
            fn: function() {
              return [
                _vm.isSelectColumn
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "grid-collection-default-cell__select",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onSelect.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm.multiselect
                            ? _c("CheckBox", { attrs: { value: _vm.selected } })
                            : _c("RadioButton", {
                                attrs: { value: _vm.selected }
                              })
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "append",
            fn: function() {
              return [
                _vm.data.actions.get
                  ? _c("Fab", {
                      staticClass: "grid-collection-default-cell__action",
                      attrs: { theme: _vm.secondaryTheme },
                      nativeOn: {
                        click: function($event) {
                          return _vm.onEdit.apply(null, arguments)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function(ref) {
                              var color = ref.color
                              return [
                                _vm.data.actions.edit
                                  ? _c("IconEdit", {
                                      attrs: { "fill-color": color }
                                    })
                                  : _c("IconPreview", {
                                      attrs: { "fill-color": color }
                                    })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3063002778
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.actions.delete
                  ? _c("Fab", {
                      staticClass: "grid-collection-default-cell__action",
                      attrs: { theme: _vm.secondaryTheme },
                      nativeOn: {
                        click: function($event) {
                          return _vm.onDelete.apply(null, arguments)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function(ref) {
                              var color = ref.color
                              return [
                                _c("IconDelete", {
                                  attrs: { "fill-color": color }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        302669064
                      )
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }