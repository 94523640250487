var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid-select-collection-header-cell" },
    [
      _c("CheckBox", {
        attrs: { value: _vm.rowsSelectionState },
        on: { input: _vm.onSelectAll }
      }),
      _vm._v(" "),
      _c("GridSelectRowActionFabCell", {
        attrs: { disabled: !_vm.isAnyData },
        on: {
          "select-all-global": _vm.onSelectAllGlobal,
          "select-all-on-this-page": _vm.onSelectAllOnThisPage,
          "deselect-all-global": _vm.onDeselectAllGlobal,
          "deselect-all-on-this-page": _vm.onDeselectAllOnThisPage
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }