var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid-collection-cell-actions-panel" },
    [
      _c(
        "div",
        { staticClass: "grid-collection-cell-actions-panel__container" },
        [_vm._t("prepend")],
        2
      ),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid-collection-cell-actions-panel__container" },
        [_vm._t("append")],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }