var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm.isPrefetchingData || !_vm.isLayoutResolved
        ? _c("Preloader")
        : [
            _vm.isSelectColumn && _vm.multiselect
              ? _c(
                  "div",
                  { staticClass: "grid-collection-layout__header" },
                  [
                    _c("GridSelectCollectionHeaderCell", {
                      attrs: {
                        "row-ids": _vm.rowIds,
                        "excluded-from-selection-rows":
                          _vm.excludedFromSelectionRows,
                        "selected-rows": _vm.selectedRows,
                        "is-selected-all": _vm.isSelectedAll
                      },
                      on: {
                        "rows-select": _vm.onRowsSelect,
                        "excluded-rows-select": _vm.onExcludedRowsSelect,
                        "select-all": _vm.onSelectAllRows
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "grid-collection-layout__body",
                style: _vm.gridTemplate
              },
              _vm._l(_vm.data, function(element, index) {
                return _c("GridCollectionCell", {
                  key: index,
                  attrs: {
                    data: element,
                    drafts: _vm.drafts,
                    "object-fit": _vm.objectFit,
                    locked: !_vm.isEditable,
                    "selected-rows": _vm.selectedRows,
                    multiselect: _vm.multiselect,
                    "excluded-from-selection-rows":
                      _vm.excludedFromSelectionRows,
                    "extended-data-cell":
                      _vm.extendedComponents.dataCells &&
                      _vm.extendedComponents.dataCells[element.type],
                    "is-selected-all": _vm.isSelectedAll,
                    "is-select-column": _vm.isSelectColumn
                  },
                  on: {
                    "rows-select": _vm.onRowsSelect,
                    "excluded-rows-select": _vm.onExcludedRowsSelect,
                    "row-action": _vm.onRowAction,
                    "cell-value": _vm.onCellValueChange
                  }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid-collection-layout__footer" })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }